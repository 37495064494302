import {FooterAction, LOAD_FOOTER_FAIL, LOAD_FOOTER_FINISH, LOAD_FOOTER_START} from '../actions/footerAction';
import {ILoadedText} from '../types/types';

const initialStore: ILoadedText = {
    textLoaded: false,
    text: {},
    error: false,
};

export function footerReducer(state: ILoadedText = initialStore, action: FooterAction): ILoadedText {
    switch (action.type) {
        case LOAD_FOOTER_START: {
            return {...state, textLoaded: false}
        }
        case LOAD_FOOTER_FINISH: {
            return {
                ...state,
                textLoaded: true,
                text: {
                    ...state.text,
                    [action.lang]: action.text,
                },
            }
        }
        case LOAD_FOOTER_FAIL: {
            return {...state, textLoaded: false, error: action.error}
        }
    }
    return state;
}

import React from 'react';

import {ClassNameFormatter, cn} from '@bem-react/classname';

import './Equalizer.css';

const equalizer: ClassNameFormatter = cn('Equalizer');

export const Equalizer = () => {
    return (
        <div className={equalizer('Wrapper')}>
            <div className={equalizer()}>
                <div/>
                <div/>
                <div/>
            </div>
        </div>
    );
};

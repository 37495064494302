export const LOAD_CONTENT_START = 'LOAD_CONTENT_START';
export const LOAD_CONTENT_FINISH = 'LOAD_CONTENT_FINISH';
export const LOAD_CONTENT_FAIL = 'LOAD_CONTENT_FAIL';

export type LOAD_CONTENT_START_TYPE = typeof LOAD_CONTENT_START;
export type LOAD_CONTENT_FINISH_TYPE = typeof LOAD_CONTENT_FINISH;
export type LOAD_CONTENT_FAIL_TYPE = typeof LOAD_CONTENT_FAIL;

export interface ILoadContentStartAction {
    type: LOAD_CONTENT_START_TYPE;
    payload: {
        hash: string;
        routeUrl: string;
    };
}

export interface ILoadContentFinishAction {
    type: LOAD_CONTENT_FINISH_TYPE;
    hash: string;
    text: string;
}

export interface ILoadContentFailAction {
    type: LOAD_CONTENT_FAIL_TYPE;
    payload: number;
    textError: boolean;
}

export type ContentAction = ILoadContentStartAction | ILoadContentFinishAction | ILoadContentFailAction;

import {connect} from 'react-redux';
import {Dispatch} from 'redux';

import {LOAD_CATEGORIES_START} from '../../actions/categoriesAction';
import {CHANGE_LANGUAGE_START} from '../../actions/languageAction';
import {IAppStore, Languages} from '../../types/types';

import {App, IAppStateProps} from './App';

const mapStateToProps = (store: IAppStore): IAppStateProps | undefined => {
    return {
            datas: store.section.categories,
            data: store.section.categories[store.lang],
            musicData: store.section.musicCategories,
            musicTops: store.section.musicTops,
            playerInfo: store.playerInfo,
            categoryLoaded: store.section.categoryLoaded,
            error: store.section.error,
            textLoaded: store.offerText.textLoaded,
            authorised: store.section.authorized,
            lang: store.lang,
        };
};

const mapDispatchToProps = (dispatch: Dispatch): {} => ({
    onInit: (lang: Languages) => {
        dispatch({type: LOAD_CATEGORIES_START, payload: lang})
    },
    onChange: (lang: Languages) => {
        dispatch({type: CHANGE_LANGUAGE_START, payload: lang})
    },
});

export const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);

import {IContentInfo} from '../types/types';

export function getJournalTitleByDate(journalItem: IContentInfo, lang: string) {
    const { description }: IContentInfo = journalItem;
    const year = Number(description.slice(0, 4));
    const month = Number(description.slice(5, 7));

    // const journalDate: string = new Date(year, month - 1).toLocaleString(lang, {
    //     month: 'long',
    //     year: 'numeric',
    // });

    return `#${month} ${year}`;
}

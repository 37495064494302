import {ICategory, IMusicTop, IPlayerInfoPayload, IPlayerStatePayload} from '../types/types';

export const CHANGE_PLAYER_INFO = 'CHANGE_PLAYER_INFO';
export const SET_PLAYLISTS = 'SET_PLAYLISTS';
export const SET_MUSIC_TOPS = 'SET_MUSIC_TOPS';
export const CHANGE_PLAYER_STATE = 'CHANGE_PLAYER_STATE';

export type CHANGE_PLAYER_INFO_TYPE = typeof CHANGE_PLAYER_INFO;
export type SET_PLAYLISTS_TYPE = typeof SET_PLAYLISTS;
export type SET_MUSIC_TOPS_TYPE = typeof SET_MUSIC_TOPS;
export type CHANGE_PLAYER_STATE_TYPE = typeof CHANGE_PLAYER_STATE;

export interface IChangePlayerInfoAction {
    type: CHANGE_PLAYER_INFO_TYPE;
    payload: {
        trackNumber: number;
        musicAlias: string;
        topNumber: number;
    },
}

export interface IChangePlayerStateAction {
    type: CHANGE_PLAYER_STATE_TYPE;
    payload: {
        isPlaying: boolean;
    },
}

export interface ISetPlaylistsAction {
    type: SET_PLAYLISTS_TYPE;
    payload: ICategory[],
}

export interface ISetMusicTopsAction {
    type: SET_MUSIC_TOPS_TYPE;
    payload: IMusicTop[];
}

export type PlayerAction = IChangePlayerInfoAction
    | ISetPlaylistsAction
    | ISetMusicTopsAction
    | IChangePlayerStateAction;

export const setPlayerInfo = (payload: IPlayerInfoPayload) => ({
    type: CHANGE_PLAYER_INFO,
    payload,
});

export const setPlayerState = (payload: IPlayerStatePayload) => ({
    type: CHANGE_PLAYER_STATE,
    payload,
});

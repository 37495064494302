import React, {Component} from 'react';

import {ClassNameFormatter, cn} from '@bem-react/classname';
import {classnames} from '@bem-react/classnames';
import {IClassNameProps} from '@bem-react/core';
import Swiper from 'react-id-swiper';

import LazyLoad from 'react-lazyload';

import {IContentInfo} from '../../../types/types';
import {PressContentItemContainer} from '../PressContentItem/PressContentItemContainer';

import './PressContentSlider.css';

const pressContentSlider: ClassNameFormatter = cn('PressContentSlider');

export interface IPressContentSlider extends IClassNameProps {
    pressContent: IContentInfo[];
    categoryAlias: string;
    page: string;
}

export class PressContentSlider extends Component<IPressContentSlider> {
    getContentItems = () => {
        const {pressContent} = this.props;
        return (
            pressContent.map((content: IContentInfo, index: number) => {
                return (
                    <span className={pressContentSlider('ContentList')} key={index}>
                        <PressContentItemContainer content={content} isSlide={true}
                                                className={classnames(pressContentSlider('Item'), 'swiper-slide')}
                                key={index} />
                    </span>
                )
            })
        )
    };

    render() {
        let nameOfPage;
        switch (this.props.page) {
            case 'main':
                nameOfPage = 'MainPage';
                break;
            case 'category':
                nameOfPage = 'CategoryPge';
                break;
            default:
                nameOfPage = 'CommonPage';
        }

        const settings = {
            loop: true,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            centeredSlides: true,
            breakpoints: {
                260: {
                    slidesPerView: 1.6,
                },
                500: {
                    slidesPerView: 3.6,
                },
                // 720: {
                //     slidesPerView: 4,
                // },
                // 900: {
                //     slidesPerView: 4,
                // },
                1024: {
                    slidesPerView: 6,
                    centeredSlides: false,
                },
                // 1200: {
                //     slidesPerView: 6,
                // },
                // 1300: {
                //     slidesPerView: 6,
                // },
                // 1500: {
                //     slidesPerView: 7,
                // },
            },
            lazy: {
                loadPrevNext: true,
                loadPrevNextAmount: 3,
            },
        };

        return (
            <div className={pressContentSlider({ofPage: nameOfPage})}>
                <LazyLoad scroll={true}>
                    <Swiper  {...settings}>
                        {this.getContentItems()}
                    </Swiper>
                </LazyLoad>
            </div>
        )
    }
}

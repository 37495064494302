import {
    LOAD_SUBSCRIPTION_FAIL,
    LOAD_SUBSCRIPTION_FINISH,
    LOAD_SUBSCRIPTION_START,
    SubscriptionAction} from '../actions/subscriptionAction';
import {ILoadedText} from '../types/types';

const initialStore: ILoadedText = {
    textLoaded: false,
    text: {},
    error: false,
};

export function subscriptionReducer(state: ILoadedText = initialStore, action: SubscriptionAction): ILoadedText {
    switch (action.type) {
        case LOAD_SUBSCRIPTION_START: {
            return {...state, textLoaded: false}
        }
        case LOAD_SUBSCRIPTION_FINISH: {
            return {
                ...state,
                textLoaded: true,
                text: {
                    ...state.text,
                    [action.lang]: action.text,
                },
            }
        }
        case LOAD_SUBSCRIPTION_FAIL: {
            return {...state, textLoaded: false, error: action.error}
        }
    }
    return state;
}

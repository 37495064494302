import React, {Component} from 'react';

import {ClassNameFormatter, cn} from '@bem-react/classname';
import {classnames} from '@bem-react/classnames';
import {IClassNameProps} from '@bem-react/core';

import './ContentText.css';

export interface IContentTextProps extends IClassNameProps {
    text?: string;
}

export class ContentText extends Component<IContentTextProps> {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    createMarkup = (jsonString: any) => {
        return {__html: jsonString};
    };

    public render() {
        const contentText: ClassNameFormatter = cn('ContentText');
        return (
            <div className={classnames(
                contentText(), this.props.className)}
                 dangerouslySetInnerHTML={this.createMarkup(this.props.text)}
            />
        )
    }
}

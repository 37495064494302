import React, {Component, ReactNode, useMemo} from 'react';

import {ClassNameFormatter, cn} from '@bem-react/classname';
import {IClassNameProps} from '@bem-react/core';

import {getRandomContentItem} from '../../helpers/getRandomContentItem';
import {ICategory, IContentInfo} from '../../types/types';
import {ContentItemPreviewContainer} from '../ContentItemPreview/ContentItemPreviewContainer';

import './ContentAdditional.css';

export interface IContentAdditionalProps extends IClassNameProps {
    category: ICategory;
    contentId: number;
    page: string;
    theme?: string;
}

const contentAdditional: ClassNameFormatter = cn('ContentAdditional');

export const ContentAdditional = (props: IContentAdditionalProps) => {

    const renderAdditionalContentItems = (categoryData: ICategory, arrayId: number[]): ReactNode[] => {
        const {contents}: ICategory = categoryData;
        const additionalContents: IContentInfo[] = contents.filter((content) => {
            return (arrayId.indexOf(content.id) !== -1);
        });

        return(
            additionalContents.map((content: IContentInfo, index: number): ReactNode => {
                let size: string;
                index < 4 ? size = 'big' : size = 'small';
                return(
                    <ContentItemPreviewContainer
                        className={contentAdditional('ContentItem')}
                        id={content.id}
                        title={content.name}
                        previews={content.previews}
                        type={content.type}
                        tags={content.tags}
                        key={content.id}
                        order={index}
                        url={content.file_hash}
                        categoryAlias={category.alias}
                        page={props.page}
                        description={content.description}
                        uidx={content.uidx}
                        size={size}
                        theme={theme && theme}
                    />
                )
            })
        )
    };

    const {category, contentId, page, theme} = props;
    const idArray = useMemo(() => getRandomContentItem(category.contents, contentId).slice(-8), [contentId]);

    return (
        <div className={contentAdditional()}>
            {renderAdditionalContentItems(category, idArray)}
        </div>
    )
}

export const  CHECK_AUTHORIZATION = 'CHECK_AUTHORIZATION';

export type CHECK_AUTHORIZATION_TYPE = typeof CHECK_AUTHORIZATION;

export interface ICheckAuthorizationAction {
    type: CHECK_AUTHORIZATION_TYPE;
    payload: {
        routeUrl: string;
    };
}

import {CHANGE_PLAYER_INFO, CHANGE_PLAYER_STATE, PlayerAction} from '../actions/playerAction';
import {IPlayerInfo} from '../types/types';

const initialStore: IPlayerInfo = {
    isPlaying: false,
    musicAlias: '',
    trackNumber: 0,
    topNumber: 0,
};

export function playerReducer(state: IPlayerInfo = initialStore, action: PlayerAction): IPlayerInfo {
    switch (action.type) {
        case CHANGE_PLAYER_INFO: {
            return {
                ...state,
                musicAlias: action.payload.musicAlias,
                trackNumber: action.payload.trackNumber,
                topNumber: action.payload.topNumber,
            }
        }
        case CHANGE_PLAYER_STATE: {
            return {
                ...state,
                isPlaying: action.payload.isPlaying,
            }
        }
    }
    return state;
}

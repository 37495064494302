import {IClassNameProps} from '@bem-react/core';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';

import {LOAD_FOOTER_START} from '../../actions/footerAction';
import {IAppStore, ICategory, Languages} from '../../types/types';

import {Footer, IFooterStateProps} from './Footer';

export interface IOwnProps extends IClassNameProps {
    categoriesData?: ICategory[];
}

const mapStateToProps = (store: IAppStore, ownProps: IOwnProps): IFooterStateProps => {
    return {
        className: ownProps.className,
        footerText: store.footerText.text[store.lang],
        footerTexts: store.footerText.text,
        error: store.footerText.error,
        textLoaded: store.footerText.textLoaded,
        language: store.lang,
    }
};

const mapDispatchToProps = (dispatch: Dispatch): {} => ({
    onLoad: (lang: Languages) => {
        dispatch({type: LOAD_FOOTER_START, payload: {
                lang,
            }})
    },
});

export const FooterContainer = connect(mapStateToProps, mapDispatchToProps)(Footer);

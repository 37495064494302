import {PRESS_ALIAS} from '../constants/constants';

export function getCategoryButtonText(alias: string) {
    switch (alias) {
        case 'pressa':
            return 'Все выпуски';
        default:
            return 'Ещё по теме';
    }
}

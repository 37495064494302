import React, {Component} from 'react';

import {ClassNameFormatter, cn} from '@bem-react/classname';
import {classnames} from '@bem-react/classnames';
import {IClassNameProps} from '@bem-react/core';

import {IContentInfo} from '../../../types/types';
import {PressContentItemContainer} from '../PressContentItem/PressContentItemContainer';

import './PressContentBlock.css';
import more from '../../Icons/chevron-right.svg';

const pressContentBlock: ClassNameFormatter = cn('PressContentBlock');

export interface IPressContentBlockProps extends IClassNameProps {
    pressContent: IContentInfo[];
    categoryAlias: string;
    page: string;
    amount?: number;
    // isMobile?: boolean;
}

export class PressContentBlock extends Component<IPressContentBlockProps> {
    state: { amount: number, text: string, isMobile?: boolean } = {
        amount: 6,
        text: 'Больше',
        isMobile: window.innerWidth < 768,
    };

    constructor(props: IPressContentBlockProps) {
        super(props);
        this.state = {amount : 6, text: 'Больше', isMobile: window.innerWidth < 768};
    }

    updateIsMobile = () => {
        this.setState({
            isMobile: window.innerWidth < 768,
        });
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateIsMobile);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateIsMobile);
    }

    componentDidUpdate(prevProps: IPressContentBlockProps) {
        if (this.props.amount !== prevProps.amount) {
            this.getContentItems();
        }
    }

    scrollToJournalTop = () => {
        setTimeout(() => {
            const selector = `${this.props.pressContent[0].name}`;
            const topElem = document.getElementById(selector);
            const y = topElem && topElem.getBoundingClientRect().top;
            window.scrollTo(0, 0);

        }, 0);
    };

    handleMore = () => {
        const {amount, isMobile} = this.state;
        const additionalAmount = isMobile ? 6 : 12;

        if (this.state.amount < this.props.pressContent.length) {
            this.setState({
                amount: amount + additionalAmount,
            });
        } else {
            this.setState({
                amount: 6,
            })
            this.scrollToJournalTop();
        }
    }

    getContentItems = () => {
        return (
            this.props.pressContent.slice(0, this.state.amount).map((content: IContentInfo, index: number) => {
                return (
                    <span className={pressContentBlock('ContentList')} key={index}>
                        <PressContentItemContainer content={content} isSlide={false}
                                                   className={classnames(pressContentBlock('Item'))}
                                                   key={index}/>
                    </span>
                )
            })
        )
    }

    render() {
        const text = (this.state.amount >= this.props.pressContent.length) ? 'Свернуть' : 'Больше';
        let nameOfPage;
        switch (this.props.page) {
            case 'main':
                nameOfPage = 'MainPage';
                break;
            case 'category':
                nameOfPage = 'CategoryPge';
                break;
            default:
                nameOfPage = 'CommonPage';
        }

        return (
            <>
                <div className={pressContentBlock({ofPage: nameOfPage})}>
                    {this.getContentItems()}
                </div>
                <div className={classnames(pressContentBlock('MoreButton', {isMore: this.state.amount < this.props.pressContent.length}))} onClick={this.handleMore}>
                    {text}
                    <img src={more} alt={'Ещё'}/>
                </div>
            </>
    )
    }
}

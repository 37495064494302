import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router-dom';
import {Dispatch} from 'redux';

import {CHECK_AUTHORIZATION} from '../../actions/checkAuthorizationtAction';
import {LOAD_CONTENT_START} from '../../actions/contentAction';
import {getContentHash} from '../../helpers/getContentHash';
import {getMatchedCategory} from '../../helpers/getMatchedCategory';
import {getMatchedContent} from '../../helpers/getMatchedContent';
import {IAppStore, ICategory, IContentInfo} from '../../types/types';

import {ContentPage, IContentPageStateProps} from './ContentPage';

export interface IContentPageRouteProps {
    alias: string;
    id: string;
}

export interface IOwnProps extends RouteComponentProps<IContentPageRouteProps> {
    categoriesData: ICategory[];
}

const mapStateToProps = (store: IAppStore, ownProps: IOwnProps): IContentPageStateProps => {
    const routeUrl: string = ownProps.location.pathname;
    const { alias, id } = ownProps.match.params;
    const { categoriesData } = ownProps;
    const matchedCategory: ICategory = getMatchedCategory(alias, categoriesData);
    const matchedContent: IContentInfo = getMatchedContent(id, matchedCategory.contents);
    let hash: string = '';
    hash = getContentHash(matchedContent.file_hash, matchedContent.uidx);

    return {
        categoriesData,
        matchedCategory,
        matchedContent,
        alias,
        id,
        hash,
        routeUrl,
        contents: store.content.contents,
        text: store.content.contents[hash],
        contentLoaded: store.content.contentLoaded,
        textError: store.content.textError,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): {} => ({
    onLoad: (hash: string, routeUrl: string) => {
        dispatch({type: LOAD_CONTENT_START, payload: {
                hash,
                routeUrl,
            },
        })
    },
    onCheck: (routeUrl: string) => {
        dispatch({type: CHECK_AUTHORIZATION, payload: {
                routeUrl,
            },
        })
    },
});

export const ContentPageContainer = connect(mapStateToProps, mapDispatchToProps)(ContentPage);

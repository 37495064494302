export const LOAD_FOOTER_START = 'LOAD_FOOTER_START';
export const LOAD_FOOTER_FINISH = 'LOAD_FOOTER_FINISH';
export const LOAD_FOOTER_FAIL = 'LOAD_FOOTER_FAIL';

export type LOAD_FOOTER_START_TYPE = typeof LOAD_FOOTER_START;
export type LOAD_FOOTER_FINISH_TYPE = typeof LOAD_FOOTER_FINISH;
export type LOAD_FOOTER_FAIL_TYPE = typeof LOAD_FOOTER_FAIL;

export interface ILoadFooterStartAction {
    type: LOAD_FOOTER_START_TYPE;
    payload: {
        lang: string;
    }
}

export interface ILoadFooterFinishAction {
    type: LOAD_FOOTER_FINISH_TYPE;
    lang: string;
    text: string;
}

export interface ILoadFooterFailAction {
    type: LOAD_FOOTER_FAIL_TYPE;
    payload: number;
    error: boolean;
}

export type FooterAction = ILoadFooterStartAction | ILoadFooterFinishAction | ILoadFooterFailAction;

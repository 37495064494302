import React, {Component} from 'react';

import {ClassNameFormatter, cn} from '@bem-react/classname';
import {classnames} from '@bem-react/classnames';
import {IClassNameProps} from '@bem-react/core';

import Hamburger from '../../Icons/Hamburger.svg';

import './HamburgerButton.css';

export interface IHamburgerButtonState extends IClassNameProps {
    onClick(): void;
}

export class HamburgerButton extends Component <IHamburgerButtonState> {
    render() {
        const hamburgerButton: ClassNameFormatter = cn('HamburgerButton');
        return(
            <div onClick={this.props.onClick} className={classnames(hamburgerButton(), this.props.className)} >
                <img src={Hamburger} className={hamburgerButton('Image')}/>
            </div>
        )

    }
}
